import cardImage_3rm from '../../assets/3rm_cardMedia.png'
import cardImage_rivertown from '../../assets/riverTown_cardMedia.png'
import cardImage_warrior from '../../assets/warrior_cardMedia.png'
import cardImage_cc from '../../assets/CC_cardMedia.png'
import cardImage_tdd from '../../assets/tdd_cardMedia.png'
import cardImage_ir from '../../assets/ir_cardMedia.png'
import cardImage_tc from '../../assets/tc_cardMedia3d.png'
import irbackground from '../../assets/ir_background.png'
import cardImage_gcc from '../../assets/gcc_cardMedia.png'

export const websites = [
    {
        title: 'Country Cloth Diapers',
        description: 'E-commerce website for a cloth diaper company',
        image: cardImage_cc,
        url: 'https://countryclothdiapers.com',
        details: 'CC details',
        colors: {
            mainTheme: '#24450C'
        },
        tech: 'WordPress'
    },
    {
        title: 'Rivertown Munitions',
        description: 'E-commerce website for an ammunition company',
        image: cardImage_rivertown,
        url: 'https://rivertownmunitions.com',
        details: 'RTM details',
        colors: {
            mainTheme: '#EC7210'
        },
        tech: 'WordPress'
    },
    {
        title: '3 Rivers Marketing',
        description: 'Website for a Marketing Company',
        image: cardImage_3rm,
        url: 'https://3rmpittsburgh.com',
        details: '3RM details',
        colors: {
            mainTheme: '#CF001F'
        },
        tech: 'WordPress'
    },
    {
        title: 'Warrior Ammunition',
        description: 'E-commerce website for a small business',
        image: cardImage_warrior,
        url: 'https://warriorammunition.com',
        details: 'WA details',
        colors: {
            mainTheme: '#EC7210'
        },
        tech: 'WordPress'
    },
    {
        title: 'Top Dog Development',
        description: 'My Agency website',
        image: cardImage_tdd,
        url: 'https://topdogwebdev.com',
        details: 'TDD details',
        colors: {
            mainTheme: '#AC0E10'
        },
        tech: 'React, Material-UI, Framermotion'
    },

]

export const apps = [
    {
        id: 1,
        title: 'Inspect Rimersburg',
        description: 'A mobile app for the Rimersburg, PA community',
        image: cardImage_ir,
        url: 'https://inspect-rimersburg.topdogwebdev.com',
        color: {
            theme1: '#1e2a59',
            theme2: '#FFF',
            theme3: '#591E2B',
            theme4: '#C7D9B6'
        },
        details: {
            problem: 'The Rimersburg Borough Council needed a better system to manage the inspection of rental properties in the borough. There was not an existing process, and it was cumbersome and time-consuming to try and find rental property information. The council needed a way to streamline the process and make it more efficient.',
            solution: 'I built a full-stack web application that allows the council to manage the inspection process. The app allows an admin to modify the criteria required for inspections. When the rental inspector is ready to inspect the property, all he has to do is click a button and a blank inspection from is dynamically rendered based on the inspection criteria. The app not only allows authorized inspectors 24/7 access to the required inspection form, it also keeps a historical record of all inspections, as well as stores all all the information on each rental property and all of the property contacts in sortable and easy to filter tables for easy access. The dashboard also provides a snapshot of the number of inspections that have been completed, as well as the number of inspections that are overdue, along with a chat section for the borough administrator to communicate with inspectors.',
            technicalDetails: 'The app is built with React, Redux, Material-UI, and Framer-Motion on the front-end, and Express, Node, and MongoDB on the back-end, and is secured with JWT authentication.',
            backgroundImg: irbackground
        },
        tech: 'React | Redux | Material-UI | JWT | Framermotion | Express | Node | MongoDB | Mongoose',
        public: false,
        demo: false,
        codeLink: 'https://inspect-rimersburg.topdogwebdev.com'
    },
    // {
    //     id: 2,
    //     title: 'Chore Aid',
    //     description: 'A mobile app for managing chores',
    //     image: null,
    //     url: 'https://chore-aid.topdogwebdev.com',
    //     color: {
    //         theme1: '#46819E',
    //         theme2: '#EBE896',
    //         theme3: '#9E2632',
    //         theme4: '#60369E'
    //     },
    //     details: {
    //         problem: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
    //         solution: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    //         technicalDetails: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    //         backgroundImg: null
    //     },
    //     tech: 'React',
    //     public: true,
    //     codeLink: ''
    // },
    {
        id: 3,
        title: 'Tow Call',
        description: 'A mobile app a towing company',
        image: cardImage_tc,
        url: 'https://gcc-council-app.topdogwebdev.com',
        color: {
            theme1: '#2E7D32',
            theme2: '#FCEBDC',
            theme3: '#402E7D',
            theme4: '#DCFCDD'
        },
        details: {
            problem: 'The main problem was that the towing company frequently had issues finding location of their customers.',
            solution: "Developed a mobile app that allows a customer to request a tow and make payment either directly from the app or from the company's url.  The application automatically pulls the gps coordinates of the customer making the request, notifies the towing company, and calculates the cost of the tow based on the location.  Once the tow is requested, the tow truck driver can simply press a button that automatically utilizes either google or apple maps based on the tow truck driver's device.",
            technicalDetails: "The UI is built with React, Material-UI, and Framer-Motion.  The backend utilizes Firebase to store the customer's information and tow request information.  The app is secured with Firebase authentication.  Payments are processed through PayPal using react-paypal-js.",
            backgroundImg: null
        },
        tech: 'React | Redux Toolkit | Material-UI | Firebase | Framer-Motion',
        public: false,
        demo: false,
        codeLink: ''
    },
    {
        id: 4,
        title: 'Grace Community Church Directory',
        description: 'Online directory for the Grace Community Church',
        image: cardImage_gcc,
        url: '',
        color: {
            theme1: '#46819E',
            theme2: '#EBE896',
            theme3: '#9E2632',
            theme4: '#60369E'
        },
        details: {
            problem: 'Grace Community Church wanted to have an online directory for their members.  Their current directory was in paper form, and it was difficult to keep up to date. Their current directory was also difficult to search through, and it was difficult to find contact information for the entire church.',
            solution: 'I built a full-stack web application that allows members of the congregation to search for information about the church, and to find contact information for the church. The app also allows the church to update their directory, and add new members, without having to get new directories printed.',
            technicalDetails: 'The app is built with React, Redux Toolkit, Material-UI, and Framer-Motion on the front-end, and utilizes Firebase for authentication and database storage.',
            backgroundImg: null
        },
        tech: 'React | Material-UI | Firebase',
        public: true,
        demo: false,
        codeLink: ''
    }
]
