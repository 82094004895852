import './App.css';
import MainHeader from './components/header/MainHeader';
import MobileMainHeader from './components/header/MobileMainHeader';
import PortfolioSection from './features/portfolio/PortfolioSection';
import Section1 from './components/About';
import About from './components/About';
import { useContext } from 'react';
import { DeviceContext } from './context/DeviceContext';
import { Box } from '@mui/material';
import Footer from './components/Footer';

document.title = "Jonathan Belloit - Portfolio";

function App() {
  const device = useContext(DeviceContext);
  const deviceType = device.device;
  return (
    <div>
      <Box sx={{ display: 'flex', backgroundColor: 'black', overflow: 'hidden'}}>
        {deviceType !== "mobile" && <MainHeader view={deviceType} /> }
        {deviceType === "mobile" && <MobileMainHeader view={deviceType} /> }
      </Box>
        <Section1 view={deviceType}/>
      <Box>
        <PortfolioSection view={deviceType} />

      </Box>
      <Footer />
    </div>
  );
}

export default App;
