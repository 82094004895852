import React from "react";
import jsicon from "../assets/js_icon.svg";
import htmlicon from "../assets/html_icon.svg";
import reacticon from "../assets/react_icon.svg";
import giticon from "../assets/git_icon.svg";
import cssicon from "../assets/css_icon.svg";
import reduxicon from "../assets/redux_icon.svg";
import materialicon from "../assets/material-ui_icon.svg";
import pythonicon from "../assets/python_logo.svg";
import mongoicon from "../assets/mongodb_icon.svg";
import sasicon from "../assets/sas_icon.svg";
import firebaseicon from "../assets/firebase_icon.svg";
import nodejsicon from "../assets/Nodejs_logo.svg";
import framermotionicon from "../assets/framer-motion.svg";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { motion } from "framer-motion";
import { Box } from "@mui/material";

const About = ({ view }) => {
  const iconStyle = {
    width: view === "mobile" ? "40px" : "65px",
    height: view === "mobile" ? "40px" : "65px",
    margin: view === "mobile" ? "3px" : "5px",
  };

  const icons = [
    { src: nodejsicon, style: iconStyle, duration: 1 },
    { src: jsicon, style: iconStyle, duration: 1.2 },
    { src: htmlicon, style: iconStyle, duration: 1.4 },
    { src: cssicon, style: iconStyle, duration: 1.6 },
    { src: reacticon, style: iconStyle, duration: 1.8 },
    { src: reduxicon, style: iconStyle, duration: 2 },
    { src: materialicon, style: iconStyle, duration: 2.2 },
    { src: pythonicon, style: iconStyle, duration: 2.4 },
    { src: giticon, style: iconStyle, duration: 2.6 },
    { src: mongoicon, style: iconStyle, duration: 2.8 },
    { src: firebaseicon, style: iconStyle, duration: 3 },
    {
      src: framermotionicon,
      style: { ...iconStyle, height: "45px" },
      duration: 3.2,
    },
    { src: sasicon, style: iconStyle, duration: 3.4 },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column',alignItems: 'center', backgroundColor: 'black', px: 2 }}>
      <Box
        component={motion.div}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 3, delay: 1.5 }}
        sx={{
          backgroundColor: "rgba(250,250,250)",
          borderRadius: 15,
          width: "100%",
          mt: 5,
          py: 1,
          px: 2,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        {icons.map((icon, index) => (
          <motion.img
            key={index}
            src={icon.src}
            style={icon.style}
            initial={{ y: -500 }}
            animate={{ y: 0 }}
            transition={{
              type: "spring",
              duration: icon.duration,
              ease: "easeInOut",
              delay: 2,
            }}
          />
        ))}
      </Box>
      <Box component={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 3.5, duration: 1 }} sx={{ display: 'flex', justifyContent: 'center' }}>
        <ExpandMoreRoundedIcon sx={{ color: 'white', textAlign: 'center', fontSize: '7rem' }}/>
      </Box>
    </Box>
  );
};

export default About;
